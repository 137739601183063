<template>
  <div v-if="isBusy">
        <md-progress-bar md-mode="indeterminate"></md-progress-bar>
  </div>
  <div v-else>
      <md-toolbar class="md-primary mb-2 fixed-toolbar" md-elevation="1">
          <h3 class="md-title" style="flex: 1" v-if="serviceType.name">{{serviceType.name}}</h3>
          <h3 class="md-title" style="flex: 1" v-else>Service type</h3>
          
          <md-button class="md-primary md-raised" @click="fireUpdate">Update service type</md-button>
      </md-toolbar>
      <div class="main-inner-wrapper">
        <CRow :gutters="true">
            <CCol sm="12" lg="5">
              <md-card>
                    <md-card-header>
                        <div class="md-title">Service type info</div>
                    </md-card-header>

                    <md-card-content>
                          <CRow>
                              <CCol sm="12">
                                  <md-field :class="getValidationClass('name')">
                                      <label>Name</label>
                                      <md-input v-model="serviceType.name"></md-input>
                                      <span class="md-error" v-if="!$v.serviceType.name.required">Name is required</span>
                                  </md-field>
                                  <md-field :class="getValidationClass('jobType')">
                                      <md-select v-model="serviceType.jobType" name="vehicle_type" id="job-type" placeholder="Service Type">
                                          <md-option v-for="jobType in jobTypes" :value="jobType.id" v-bind:key="jobType.id">{{jobType.name}}</md-option>
                                      </md-select>
                                      <span class="md-error" v-if="!$v.serviceType.jobType.required">Service type is required</span>
                                  </md-field>
                                  <md-field>
                                      <label>Type</label>
                                      <md-input v-model="serviceType.type" readonly></md-input>
                                  </md-field>
                                  <md-field :class="getValidationClass('vehicleType')">
                                      <md-select  v-model="serviceType.vehicleType" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type" multiple>
                                          <md-option v-for="vehicleType in vehicleTypes" :value="vehicleType.id" v-bind:key="vehicleType.id">{{vehicleType.type}}</md-option>
                                      </md-select>
                                      <span class="md-error" v-if="!$v.serviceType.vehicleType.required">Vehicle Type is required</span>
                                  </md-field>
                              </CCol>
                          </CRow>
                          <CRow >
                              <CCol sm="12" class="mt-3">
                                <md-button class="md-raised md-accent full-width-button " @click="destroy">Delete service type</md-button>
                            </CCol>
                        </CRow>
                          
                      </md-card-content>
                
                </md-card>
            </CCol>
        </CRow>
      </div>
      <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
          <span>{{snackMessage}}</span>
          <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
      </md-snackbar>

  </div>
  
</template>

<script>
import firebase from '../../Firebase'
import { validationMixin } from 'vuelidate'
import {
    required,
    between,
    email,
    minLength,
    maxLength
} from 'vuelidate/lib/validators'
export default {
  name: 'ServiceType',
  mixins: [validationMixin],
  data() {
    return {
      showSnackbar: false,
      position: 'center',
      duration: 7000,
      isInfinity: false,
      snackMessage: '',
      serviceType: null,
      vehicleTypes: [],
      isBusy: true,
      jobTypesRef:  this.$firebase.firestore().collection('job-types'),
      //ref: firebase.firestore().collection('users').doc(this.$root.uid).collection('service-types')
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
      
  },
  created () {
    let th = this;

     
    
    
    this.obtainViewResources()
    

  },
  methods: {
    async obtainViewResources() {
      this.isBusy = true;
      await this.getJobtypes()
      await this.getServiceType()
      await this.getVehicleTypes()
      this.isBusy = false;
    },
    async getJobtypes() {
      let th = this;
      return new Promise((resolve, reject) => {
          th.jobTypesRef.orderBy('order').onSnapshot((querySnapshot) => {
          th.jobTypes = [];
            querySnapshot.forEach((doc) => {

                let dataToSave = doc.data();
                dataToSave.id = doc.id ;
              th.jobTypes.push(dataToSave);
                
            });
            resolve()
        });
      }) 
    },
    async getServiceType() {
      let th = this;
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('users').doc(this.user.data.uid).collection('service-types').doc(this.$route.params.id).get()
        .then(function(doc) {
          if (doc.exists) {
              console.log("Document data:", doc.data());
              th.serviceType = doc.data();
              
              resolve()
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
              reject()
          }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
      })  
    },
    async getVehicleTypes() {
      let th = this;
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('users').doc(th.user.data.uid).collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
            th.vehicleTypes = [];
            querySnapshot.forEach((doc) => {

              let dataToSave = doc.data();
              dataToSave.id = doc.id ;
              th.vehicleTypes.push(dataToSave);
              

            });    
            
            resolve()
            
        });
      })  
    },
    fireUpdate() {
      if(this.validate()) {
        this.update()
      } else {
        this.$v.serviceType.$touch()
      }
    },
    update() {
      let th = this;
      this.isBusy = true;
      firebase.firestore().collection('users').doc(this.user.data.uid).collection('service-types').doc(this.$route.params.id).update(th.serviceType)
      .then(function(){
        console.log('Updated');
        th.snackMessage = 'Service type updated';
        th.isBusy = false;
        th.showSnackbar = true;
        //th.$router.push({path: `/app/service-types`})
      }).catch(function(error) {
         th.snackMessage = 'Ooopss something went wrong';
          th.isBusy = false;
          th.showSnackbar = true;
          console.log("Error getting document:", error);
      });
    },
    destroy() {
      let th = this;
      firebase.firestore().collection('users').doc(this.user.data.uid).collection('service-types').doc(this.$route.params.id).delete()
      .then(function(){
        console.log('Deleted');
        th.$router.push({path: `/app/service-types`})
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });;
    },
    validate(){
        let name,jobType,vehicleType;
        name = this.$v.serviceType.name.$invalid;
        jobType = this.$v.serviceType.jobType.$invalid;
        vehicleType = this.$v.serviceType.vehicleType.$invalid;
        
        return !name && !jobType && !vehicleType? true : false ;

    },
    getValidationClass (fieldName) {
        let field = null;

        
        field = this.$v.serviceType[fieldName];
        
        
        
        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },
  },
  validations: {
       
        serviceType: {
            
            name: {
                required
            },
            jobType: {
                required
            },
            vehicleType: {
                required
            }
            
        }
    }
}



//db.collection('books').doc('fK3ddutEpD2qQqRMXNW5').get()
</script>

